import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://dwarven-explorer-gmb8dmd7gyd8ejh5.uksouth-01.azurewebsites.net/DwarvenExplorer/DwarvenExplorer/GetWalletsTotalMineValue')
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="App">
      <h1>Our best miners!</h1>
      <table>
        <thead>
          <tr>
            <th>Wallet</th>
            <th>Total Value Mined</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => (
            <tr key={item.address}>
              <td>{item.address}</td>
              <td>{item.totalValueMined}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default App;